<template>
	<div class="viewEdu" :style="[mini ? {'margin':'0 0 0 0px'} : {'margin':'0 0 0 '+(left_drawer_width-55)+'px'}]"  >
        <eduAll :selModuleProp=selModule :selTaskProp=selTask></eduAll>
        <v-navigation-drawer
            v-model="left_drawer"
            :mini-variant.sync="mini"
            permanent
            absolute
            v-bind:width="left_drawer_width"
        >
            <v-card>
                <v-list-item class="px-2">
                    <v-list-item-avatar @click="toggleMini">
                        <v-icon color="indigo" class="mr-2">mdi-menu</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title>
                        Kies module
                    </v-list-item-title>
                    <v-btn icon @click="toggleMini">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </v-list-item>

                <v-divider></v-divider>

                <v-list dense>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon color="indigo">mdi-script-text-play-outline</v-icon>
                        </v-list-item-icon>
                    
                        <v-list-item-content>
                            <!-- <v-list-item-title class="text-h6"><span>module</span></v-list-item-title> -->
                            <v-list-item-subtitle>
                                <v-select dense :items="modules" item-text="module" label="module" return-object filled v-model="selModule" @change="changemodule">
                                </v-select>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    
                    <!-- <v-list-item v-if="selModule">
                        <v-list-item-icon>
                            <v-icon color="indigo">mdi-script-text-outline</v-icon>
                        </v-list-item-icon>
                    
                        <v-list-item-content>
                            <v-list-item-title class="text-h6">
                                <span>{{selModule.Module}}</span>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                <v-select dense :items="selModule.tasks" item-text="title" return-object filled label="taak" v-model="selTask">
                                </v-select>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item> -->

                    <div v-for="(task, index) in selModule.tasks" :key="index" class="d-flex flex-row">
                        <v-list-item v-if="selModule">
                            <v-avatar  size="25" class="av_a"> {{index+1}}</v-avatar>
                               
                            <v-list-item-content>
                                <v-btn @click="selTask=task">{{task.title}}</v-btn>
                            </v-list-item-content>
                        
                        </v-list-item>
                    </div>


                </v-list>
            </v-card>
        </v-navigation-drawer>

	</div>
</template>

<script>
import eduAll from "@/components/edu_pages/edu_all";
import { getDoelen} from "@/api/edu.js";

export default {
	//name: "",
	components: {
        eduAll,
	},

	data: () => ({
        selFile: "",
        selModule: null,
        selTask: {},
        mini: true,
        left_drawer:true,
        left_drawer_width:250,
        modules: [
            {   module: "Colorgame", 
                id:"cg",
                tasks: [
                    {title: "Colorgame part 1", id: "cg10"},
                    {title: "Colorgame part 2", id: "cg20"},
                    {title: "Colorgame part 3", id: "cg30"},
                    {title: "Colorgame part 4", id: "cg40"},
                    {title: "Colorgame part 5", id: "cg50"},
                ] 
            },
            {   module: "CSP1", 
                id: "csp1", 
                tasks: [
                    {title: "CSP1 part 1", id: "csp1_10"},
                    {title: "CSP1 part 2", id: "csp1_20"},
                    {title: "CSP1 part 3", id: "csp1_30"},
                    {title: "CSP1 part 4", id: "csp1_40"},
                    {title: "CSP1 part 5", id: "csp1_50"},
                ] 
            },
            /* {   module: "CSP2", 
                id: "csp2", 
                tasks: [
                    {title: "CSP2 part 1", id: "csp2_10"},
                    {title: "CSP2 part 2", id: "csp2_20"},
                    {title: "CSP2 part 3", id: "csp2_30"},
                    {title: "CSP2 part 4", id: "csp2_40"},
                    {title: "CSP2 part 5", id: "csp2_50"},
                ] 
            }, */
            {   module: "pAO sem. 2.2", 
                id: "pr22",
                tasks: [
                    {
                        title: "Inleiding pAO", 
                        id: "pr22A",
                        deadline:"",
                        leerdoelen: {

                        }
                    },
                    {
                        title: "Fase 1", 
                        id: "pr22B",
                        deadline: "",
                        leerdoelen: {
                            "HTML": [
                                "HTML tables", 
                                "HTML forms (input type=\"hidden\", submit)",
                                "CSS",
                            ],
                            "PHP": [
                                "Database connectie maken (PDO of MySQLi, of..)", 
                                "SQL (Read) query uitvoeren en result naar HTML schrijven",
                                "Formulier-data vanuit HTML-form opvangen via POST en GET",
                                "SQL Create-Read-Update-Delete acties kunnen doorvoeren op 1 tabel",
                            ],
                            "Databases": [
                                "PHPMyAdmin: tables aanmaken", 
                                "SQL-Query: SELECT, WHERE, ORDER BY, UPDATE",
                                "SQL-Query: JOIN, GROUP BY, COUNT", 
                                "SQL-Query: CRUD (Create, Read, Update)", 
                            ],
                            "SCRUM": [
                                "Communiceren in de groep", 
                                "Samenwerken in de groep", 
                            ],
                        } 
                    },
                    {
                        title: "Fase 2", 
                        id: "pr22C",
                        deadline: "",
                        leerdoelen: {
                            
                            "PHP": [
                                "IP adressen ophalen",
                            ],
                            "Databases": [
                                "SQL-Query: JOIN, GROUP BY, COUNT", 
                                "SQL-Query: CRUD (Create, Read, Update, Delete)", 
                            ],
                            
                            "SCRUM": [
                                "Basic GitHub gebruik",
                                "Alle teamleden op 1 repository: push, pull acties uitvoeren",
                            ],
                        }                     
                    },
                    {
                        title: "Fase 3", 
                        id: "pr22D",
                        deadline: "rond 10 februari 2022",
                        leerdoelen: { 
                            "SCRUM": [
                                "Inrichten Scrumtool in Azure",
                                "Product Backlog items aanmaken",
                                "Taken onder PBL ietems aanmaken",
                                "Teamleden en uren aan taken toekennen",
                                "Sprints aanmaken",
                                "Taken op done slepen",
                                "Burndown interpreteren",
                            ],
                        } 
                    },
                    {
                        title: "Fase 4", 
                        id: "pr22E",
                        deadline: "rond 14 februari 2022",
                        leerdoelen: {} 
                    }, //&eacute;n 
                    {
                        title: "Fase 5", 
                        id: "pr22F",
                        deadline: "<ul><li>Groeps-oplevering product:<ul><li>1 maart 2022</li> <li>8 maart 2022</li></ul></ul><li>zie <a href='https://edu.stegion.nl/files/presentatie_PAO_nl.pdf' target='_blank'>deze</a> PDF voor beoordeling presentatietechnieken</li></ul> ",
                        leerdoelen: {
                            "HTML": [
                                "HTML tables", 
                                "HTML forms (input type=\"hidden\", submit)",
                                "CSS",
                                "HTML vanuit JSON kunnen opbouwen",
                            ],
                            "PHP": [
                                "Database connectie maken (PDO of MySQLi, of..)", 
                                "SQL (Read) query uitvoeren en result naar HTML schrijven",
                                "Formulier-data vanuit HTML-form opvangen via POST en GET",
                                "SQL Create-Read-Update-Delete acties kunnen doorvoeren",
                                "IP adressen ophalen",
                                "JSON kunnen genereren",
                            ],
                            "Databases": [
                                "PHPMyAdmin: tables aanmaken", 
                                "SQL-Query: SELECT, WHERE, ORDER BY, UPDATE",
                                "SQL-Query: JOIN, GROUP BY, COUNT", 
                                "SQL-Query: CRUD (Create, Read, Update, Delete)", 
                            ],
                            "SCRUM": [
                                "Communiceren in de groep", 
                                "Samenwerken in de groep", 
                                "Basic GitHub gebruik",
                                "Alle teamleden op 1 repository: push, pull acties uitvoeren",
                                "Inrichten Scrumtool in Azure",
                                "Product Backlog items aanmaken",
                                "Taken onder PBL ietems aanmaken",
                                "Teamleden en uren aan taken toekennen",
                                "Sprints aanmaken",
                                "Taken op done slepen",
                                "Burndown interpreteren",
                            ],
                            "Javascript": [
                                "met AJAX-calls, JSON kunnen ophalen", 
                                "debuggen via console",
                                "HTML genereren met JS vanuit JSON",
                                "eventueel: jQuery gebruik",
                            ],
                            "Presenteren": [
                                // "groepsvoorbereiding presentatie",
                                // "woordvoerders verdelen",
                                // "soepele flow van presentatie (via 1 laptop indien mogelijk)",
                                "zie richtlijnen voor presenteren bij burgerschap / nederlands",
                                "technische toevoeging: globaal uitleggen: verschil fase 4 => fase 5",
                                "technische toevoeging: beamergebruik",
                            ],

                        } 
                    },
                ] 
            }
        ],

	}),

    watch: {
		//wanneer  er nog al "/edu" in de route staat ververst hij niet bij "/edu/csp1" o.i.d., met een watch op de $route wel
		$route: function (newItems) { //, oldItems
			if (newItems) {
				this.getModuleTasks();
			}
		},

        // mini: function (newItems) { //, oldItems
		// 	if (newItems) {
		// 		console.log("mini ", this.mini)
		// 	}
		// },

	},

    created() {
        // voor als er nog geen "/edu" in de route stond
        this.getModuleTasks(); 
        //console.log("edu.vue, in created this.modules:", this.modules);
        //console.log("window.location.href="+ window.location.href);

         getDoelen().then((apiData) => {
            //console.log("getDoelen() apiData:", apiData);
			if (apiData.bSuccess) {
                    this.bLoaded = true;
                    this.items = apiData.data;
                    //console.log("in edu.vue, created, getDoelen() this.items:", this.items);
                } else {
                    // this.$root.$emit("showSnackbar", {
                    //     text: "7) "+apiData.sMessage,
                    //     type: "error",
                    //     time: 7000,
                    // });
                }
            return null //response.data.data
        });

        //this.getBaseUrlFiles()


        //let sql ="";

        /* 
            console.log("this.modules[2].tasks = ",this.modules[2].tasks); 
                this.modules[2].tasks.forEach((val, index) => {
                console.log("val = ",val); console.log("index = ",index);
                sql += "INSERT INTO `taak` (`id`, `idModule`, `volgorde`   , `afkorting`, `title`, `omschrijving`, `criteria`, `bBeo`)";
                sql += "            VALUES (NULL, '6'       , '"+(index+1)+"', 'F"+(index)+"', '"+val.title+"', '"+"', '', '0');\n";
            });
        */
        // console.clear();
        // console.log("this.modules[2].tasks[5].leerdoelen = ",this.modules[2].tasks[5].leerdoelen); 
        // let aItems = Object.keys(this.modules[2].tasks[5].leerdoelen)
        // console.log("Object.keys(this.modules[2].tasks[5].leerdoelen) = ",aItems); 

        // sql += " TRUNCATE doel__categorie; \n";
        // sql += " TRUNCATE doel; \n";
        // aItems.forEach((val, index) => {
        //     //console.log("val = ",val); console.log("index = ",index);
        //     sql += "INSERT INTO `doel__categorie` (`id`, `id_cat_parent`, volgorde, `categorie`, `omschrijving`)";
        //     sql += "                       VALUES (NULL, '0'            , '"+(index+1)+"'  ,'"+val+"'  , ''        );\n";

        //     sql += " SET @lastID = LAST_INSERT_ID();\n";

        //     //Object.keys(aItems[val]).forEach((val2, index2) => {
        //     this.modules[2].tasks[5].leerdoelen[val].forEach((val2, index2) => {
        //         console.log("val = ",val); console.log("val2 = ",val2); console.log("index2 = ",index2);
        //         sql += "INSERT INTO `doel` (`id`, id_cat , volgorde        , `doel`    , `opmerking`)";
        //         sql += "            VALUES (NULL, @lastID, '"+(index2+1)+"','"+val2+"' , ''         );\n";
        //         //SELECT LAST_INSERT_ID();
        //     });
        // });
        // console.log(sql);
	},

    methods: {
        toggleMini: function() {
            this.mini = !this.mini;
            if(this.mini) {
                    //
            }
            //console.log("in toggleMini. this.mini = "+this.mini);
        },

        changemodule: function() {
            this.selTask = this.selModule.tasks[0];
            //console.log("in changemodule. ");
            this.mini = false;
        },

        getModuleTasks: function() {
            //console.log("newItems", newItems); //console.log("oldItems", oldItems);console.log("this.$route.path =", this.$route.path);
                //console.log("this.modules =", this.modules);
                //console.log("this.$route.path =", this.$route.path);
                if(this.$route.path == "/edu/pr22") {
                    const index = this.modules.findIndex(object => {return object.id === "pr22";});
                    this.selModule = this.modules[index];
                    //console.log("index =", index);
                }
                if(this.$route.path == "/edu/csp1") {
                    const index = this.modules.findIndex(object => {return object.id === "csp1";});
                    this.selModule = this.modules[index];
                    //console.log("index =", index);
                }
                if(this.$route.path == "/edu/cg") {
                    const index = this.modules.findIndex(object => {return object.id === "cg";});
                    this.selModule = this.modules[index];
                    //console.log("index =", index);
                }
            
                if(!this.selModule) {
                    this.selModule = this.modules[0];
                }
                this.selTask = this.selModule.tasks[0];
                // console.log("this.selModule =", this.selModule);
                // console.log("this.selTask =", this.selTask);
        },
	},
};
</script>

<style scoped>
    .av_a {
        background-color: darkslategrey;
        color:white!important;
        margin-right:15px;
    }
</style>
