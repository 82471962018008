import axios from "axios";
import store from '../store'

//import baseUrlAction from './api_ini.js'

export const getDoelen = async () => {
    try {
        let baseUrlAction = store.state.baseArrays.appPaths.baseUrlAction;
        const response = await axios.get(baseUrlAction + 'getDoelen');
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

export const getModules = async () => {
    try {
        let baseUrlAction = store.state.baseArrays.appPaths.baseUrlAction;
        const response = await axios.get(baseUrlAction + 'getModules');
        //console.log("in edu.js, response van getDoelen:", response.data);
        return response.data;
    } catch (err) {
        console.error(err);
    } 
};

export const getTakenPerModId = async (idModule) => {
    try {
        let baseUrlAction = store.state.baseArrays.appPaths.baseUrlAction;
        //console.log("idModule =" + idModule);
        const payload = { idModule: idModule }; // volgorde velden maakt niet meer uit door aanpassing in API  
        const response = await axios.post(baseUrlAction + 'getTakenPerModId', payload);
        //console.log("in edu.js, response van getTakenPerModId:", response);
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

export const getRijenPerTaakId = async (id_taak) => {
    try {
        let baseUrlAction = store.state.baseArrays.appPaths.baseUrlAction;
        //console.log("in edu.js, response van getRijenPerTaakId, id_taak:" + id_taak);
        const payload = { id_taak: id_taak }; // volgorde velden maakt niet meer uit door aanpassing in API  
        const response = await axios.post(baseUrlAction + 'getRijenPerTaakId', payload);
        //console.log("in edu.js, response van getRijenPerTaakId:", response);
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

// export const getBeers = async () => {
//     let url = baseUrlAction + 'getBeers2';
//     // let payload = { gisting: 'hoge met nof', id: 110, }; // volgorde velden maakt niet meer uit door aanpassing in API
//     // let response = await axios.post(url, payload);
//     // console.log("response from post getBeersrequest", response);
//     // const payload = JSON.stringify({ itemId, qty });
//     //const payload = { gisting: 'hoge met nof', id: 110, }; // volgorde velden maakt niet meer uit door aanpassing in API
//     const payload = { gisting: gisting, id: id }; // volgorde velden maakt niet meer uit door aanpassing in API
//     const response = await axios.post(url, payload);
//     console.log("response from post getBeers request from bier.js", response);
//     return response
// }

// export {
//     getBeers,
//     //getBeers2
// }

//api/product.js  
// export const addToCart = async (itemId, qty) => {
//     const payload = JSON.stringify({ itemId, qty });
//     const response = await axios.post("/api/cart", {
//         body: payload
//     });
//     const body = await response.json();
//     return body;
// }
