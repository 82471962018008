<template>
    <div >
        <!-- selModule = {{selModule}} <br>selTask = {{selTask}}<hr>selTask = {{selTask.title}} id = {{selTask.id}} -->
        <div elevation="3" class="eduRow eduHeader px-6 py-4" >
            <div v-if="selModule && selTask">
                <div class="d-flex align-center ">
                    <v-card elevation="4" color="primary" class="ma-2">
                        <div class="headSubTitles">Module</div> 
                        <div class="headTitles">{{selModule.module}}</div>
                    </v-card>
                    <v-card elevation="4" color="primary" class="ma-2 ml-4 ">
                        <div class="headSubTitles">Taak </div> <!-- ({{selTask.id}}) -->
                        <div class="headTitles">{{selTask.title}}</div>
                    </v-card>
                    
                    <v-card elevation="4" color="primary" class=" ma-2 ml-auto">
                        <v-btn 
                            icon 
                            @click="nextPage(false)" 
                            class="white--text"
                            :disabled="selModule.tasks[0].id == selTask.id"
                        >
                            <v-icon>mdi-arrow-left-bold-box-outline</v-icon>
                        </v-btn>
                        <v-btn  
                            icon 
                            @click="nextPage(true)" 
                            class="white--text"
                            :disabled="selModule.tasks[selModule.tasks.length-1].id == selTask.id"
                        >
                            <v-icon>mdi-arrow-right-bold-box-outline</v-icon>
                        </v-btn>
                    </v-card>
                </div>
            </div>
            <div v-else>
                <h3 color="red">Geen module geselecteerd</h3>
            </div>
        </div>

        <div class="eduModules">
<!-- ---------- start --------------- colorgame ------------------------------------------------------------------------- -->
            <div v-if="selTask && selTask.id =='cg10'" class="px-6">
                
                <v-card class="eduRow">
                    <v-card class="rowTitle" @click="toggleExpansion">
                        Game: Hit the color!
                    </v-card>
                    <v-row>
                        <v-col sm="12" md="8" >
                            <v-card>
                                In deze lesreeks ga je de <i>colorgame</i> maken, een snelheids-spelletje op basis van HTML, CSS en Javascript.<br>
                                Deze lessen vallen in de projectweken van SD in januari 2022 en zijn de start van het vak CSP1 in het komende semester.<br>
                                <br>
                                <i style="color:darkred;"><b>Huiswerk SD1A</b> voor donderdag 20 januari: In ieder geval af tot "Colorgame 5_0"</i>
                            </v-card>
                        </v-col>
                        <v-col sm="12" md="4">
                            <v-card>
                                <img style="max-width:100%;" src="./img/game_overview.png" />
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card class="eduRow">
                    <v-card class="rowTitle">
                        Opdracht:
                    </v-card>
                    <v-row>
                        <v-col md="4" sm="12">
                            <v-card>
                                Maak een basisgrid van 9x9 hokjes, met een basiskleur-balk eronder
                            </v-card>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col md="8" sm="12">
                            <v-card class="text-center">
                                <div><i>Voorbeeld van output</i></div>
                                <img src="./img/game1.png" style="height:250px" />
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>

                <!-- <v-card class="eduRow">
                    <v-card class="rowTitle">
                        -
                    </v-card>
                    <v-row>
                        <v-col md="12" sm="12">
                            <v-card class="text-center">
                                -
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card> -->

                <v-card class="eduRow">
                    <v-card class="rowTitle">
                        Toelichting
                    </v-card>
                    <v-row>
                        <v-col md="12" sm="12">
                            <v-card>
                                <ul>
                                    <li>We willen 9 hokjes van 50px x 50px in een vierkant. Dat kan met:
                                        <ul>
                                            <li>divjes (en Flexbox, geen float meer gebruiken!)</li>
                                            <li>(CSS) grid</li>
                                            <li>HTML table</li>
                                            <li>meer..?</li>
                                        </ul>
                                    </li>
                                    <li>=&gt; makkelijkste optie: de flexbox! <i>(omdat je straks met JS er ook 10, 15, 25 cellen van moet kunnen maken)</i><br>
                                        Google op "flexbox w3schools". Open de 1<sup>ste</sup> "try it yourself" 
                                        <ul>
                                            <li>in CSS: maak de hokjes 50px x 50px en geef ze 1px margin</li>
                                            <li>voeg een 9<sup>de</sup> toe</li>
                                            <li>verander de "nowrap" van de container in "wrap"</li>
                                        </ul>
                                        <span style="color:darkred; font-style:italic;">
                                            Je kunt deze code van w3schools als basi gebruiken voor jouw game1_0<br>
                                            Maak voor elke stap van de game een nieuw bestand aan en voorkom dat je oude code overschrijft!!
                                        </span>
                                    </li>
                                    <li>
                                        Maak onder het 9<sup>de</sup> hokje nog een 1<sup>de</sup> aan en maak deze 3 x zo breed (houd rekening met de 1px margin van de hokjes erboven)
                                    </li>

                                </ul>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </div>

            <div v-if="selTask && selTask.id =='cg20'" class="px-6">
                <v-card class="eduRow">
                    <v-card class="rowTitle">
                        Page 2 colorgame
                    </v-card>
                    <v-row>
                        <v-col sm="12" md="8" >
                            <v-card>
                            Pagina 2 colorgame en rest colorgame op <a href="https://std.stegion.nl/csp1/colorgame/game2_0.html">deze</a> site.
                            </v-card>
                        </v-col>
                        <v-col sm="12" md="4">
                            <v-card>
                                <img style="max-width:100%;" src="./img/game_overview.png" />
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
<!-- ---------- stop ---------------- colorgame ------------------------------------------------------------------------- -->

<!-- ---------- start --------------- project s2.2 ---------------------------------------------------------------------- -->
            <div v-if="selTask && selTask.id =='pr22A' " class="px-6">
                <v-card class="eduRow">
                    <v-card class="rowTitle">
                        Inleiding pAO
                    </v-card>
                    <v-row>
                        <v-col sm="12" md="10" >
                            <v-card>
                                <div style="border:1px solid gray; border-radius:3px;" 
                                     class="pa-2 mb-2">
                                    <h3>Project 1 (opstartfase): "We like bier"</h3>
                                    <div>
                                        "We like bier" is een project voor The Belgian Beer Company om meer naamsbekendheid voor 
                                        belgische speciaal bieren te creeëren onder studenten en young adults.
                                    <br>
                                        Doel van het van dit 1<sup>ste</sup> in semester 2.2 is, om alle aspecten te leren kennen
                                        die te maken hem met het opzetten van een applicatie m.b.v. Scrumtechnieken
                                    </div>
                                    Looptijd project 1:<br>
                                        <ul>
                                            <li>Sprint:
                                                <ul>
                                                    <li>start: ma 31 januari 2022</li>
                                                    <li>stop: vr 18 april</li>
                                                    <li>
                                                        oplevering product: di 8 en 15 maart april<br>
                                                        Richtlijnen presentatie: zie <a href='https://edu.stegion.nl/files/presentatie_PAO_nl.pdf' target='_blank'>deze</a> PDF
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                </div>
                                <div style="border:1px solid gray; padding:10px; border-radius:3px;">
                                    <h3>Project 2: project voor klant / school</h3>
                                    <div>
                                        <ul>
                                            <li>
                                                <strong>Zorg dat je bij de start van de sprint de userstories met taken en urenverdeling gekoppeld aan team leden hebt ingevuld!!</strong><br>
                                                <i>Voeg je pas later uren, gekoppeld aan teamleden toe, dan klopt je burndown niet</i></li>
                                            <li>
                                                <strong>Plan <i>zelf (met je team)</i>, de oplevering en retro aan het eind van de sprint met de klant/docent </strong>
                                            </li>
                                        </ul>
                                       
                                       
                                        <hr><br>
                                        
                                        <h3>Looptijd sprints:</h3>
                                        <ul>
                                            <li><strong>Sprint 1:</strong>
                                                <ul>
                                                    <li>start: 28 mrt 2022</li>
                                                    <li>stop:  8 april 2022</li>
                                                    <li>
                                                        oplevering retro: di 12 april<br>
                                                        Richtlijnen retro: zie <a href='https://edu.stegion.nl/files/paoRetroAndel.pdf' target='_blank'>deze</a> PDF
                                                    </li>
                                                </ul>
                                            </li>
                                            <li><strong>Sprint 2:</strong>
                                                <ul>
                                                    <li>start: 11 april 2022</li>
                                                    <li>stop: 13 mei 2022</li>
                                                </ul>
                                            </li>
                                            <li><strong>Sprint 3:</strong>
                                                <ul>
                                                    <li>start: 16 mei 2022</li>
                                                    <li>stop: 3 juni 2022</li>
                                                </ul>
                                            </li>
                                            <li><strong>Sprint 4:</strong>
                                                <ul>
                                                    <li>start: 6 juni 2022</li>
                                                    <li>stop: 24 juni 2022</li>
                                                </ul>
                                            </li>
                                        </ul>
                                   
                                    </div>
                                </div>
                            
                                <br>
                                <h3>Werkwijze projecten pAO</h3>
                                Tijdens de pAO uren (project Applicatie Ontwikkeling), gaan we als volgt te werk:
                                <ul>
                                    <li>werken in groepen van 4 studenten</li>
                                    <li>op deze site staan de <i>opdrachten onder de <b>taken</b> met als naam: <b>fase ..</b></i></li>
                                    <li>de verschillende aspecten van Software Development leer je bij de vakken:  
                                        <ul>
                                            <li>PHP2</li>
                                            <li>CSP2</li>
                                            <li>APP</li>
                                            <li>Security</li>
                                            <li>pAO zelf</li>
                                        </ul>
                                    </li>
                                </ul>
                                <br>
                                <h3>Leerdoelen en taken</h3>
                                Aan elke taak/opdracht hangen leerdoelen op 3 verschillende niveaus:
                                <ol>
                                    <li>Is bekend met ... </li>
                                    <li>Kan ... in de praktijk toepassen</li>
                                    <li>Is beginnend beroepsbeoefenaar in het toepassen van ...</li>
                                </ol>
                                Doelen kunnen zijn:
                                <ul>
                                    <li>for-loops bij programmeertalen</li>
                                    <li>een UPDATE query schrijven</li>
                                    <li>taken van de productowner</li>
                                </ul>
                                Aan elke leerdoelen kan ook een specifieke taal of tool gekoppeld zijn zoals:
                                <ul>
                                    <li>Laravel (PHP)</li>
                                    <li>MySQL</li>
                                    <li>Azure DevOps</li>
                                </ul>
                                Je hebt taken of opdrachten afgerond als alle leerdoelen op een bepaald niveau zijn afgevinkt
                            </v-card>
                        </v-col>
                        <v-col sm="12" md="2">
                            <v-card>
                                <img style="max-width:100%;" src="./img/pr22_project.png" />
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card class="eduRow">
                    <v-card class="rowTitle">
                        Projecten
                    </v-card>
                    <v-row>
                        <v-col sm="12" md="10" >
                            <v-card>
                                <h3>Projecten en lessen</h3>
                                <p>
                                    Vanaf het 2<sup>de</sup> semester in jaar 2 ga je in projectgroepen samen werken en leer je
                                    verschillende aspecten van Software development zoals:
                                    <ul>
                                        <li>Technische vaardigheden (ondersteunende lessen)
                                            <ul>
                                                <li>Programmeertalen zoals in PHP, Javascript</li>
                                                <li>Gebruik van Libraries en Frameworks zoals Laravel, jQuery, Vue</li>
                                                <li>Gebruik van SD-tools zoals Balsamiq, Draw.io, MySQL Workbench</li>
                                                <li>..</li>
                                            </ul>
                                        </li>
                                        <li>Sociaal en communicatieve vaardigheden
                                            <ul>
                                                <li>Werken in groepsverband</li>
                                                <li>Leiding geven</li>
                                                <li>Presenteren</li>
                                                <li>..</li>
                                            </ul>
                                        </li>
                                        <li>Project management
                                            <ul>
                                                <li>Scrum</li>
                                                <li>Omgaan met tools als Azure DevOps</li>
                                                <li>Opzetten van projectplan / wireframing</li>
                                                <li>..</li>
                                            </ul>
                                        </li>
                                        <!-- <li>
                                            <ul>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                                <li></li>
                                            </ul>
                                        </li> -->
                                    </ul>
                                </p>
                                <p>
                                    Doel van het van dit 1<sup>ste</sup> in semester 2.2 is, om alle aspecten te leren kennen
                                    die te maken hem met het opzetten van een applicatie m.b.v. Scrumtechnieken
                                </p>
                            </v-card>
                        </v-col>
                        <v-col sm="12" md="2">
                            <v-card>
                                <img style="max-width:100%;" src="./img/pr22_project.png" />
                            </v-card>
                        </v-col>

                    </v-row>
                </v-card>

                <v-card class="eduRow">
                    <v-card class="rowTitle">
                        Scrum
                    </v-card>
                    <v-row>
                        <v-col sm="12" md="8" >
                            <v-card>
                                <!-- <h3>Wat is scrum?</h3> -->
                                <p>
                                    Vroeger werd bij de opzet van Software development projecten eerst het hele project uitgedacht en 
                                    helemaal uitgeschreven, dat is de <i>waterfall</i>-methode. Zo'n project kon jaren duren.<br>
                                    Tijdens de ontwikkeling van een applicatie komt men vaak op anere ide&euml;en, onverwachte problemen en
                                    komt de opdrachtgever er vaak pas later achter, dat er iets anders uitkwam dan hij had verwacht. 
                                </p>
                                <p>
                                    Dat soort trajecten kost vaak veel tijd en geld, daarvoor is de <i>Scrum</i>-methodiek ontwikkeld.
                                </p>
                                <p>
                                    Lees <a href="https://www.scrum.nl/wat-is-scrum-agile-scrum/">hier</a> meer over Scrum.
                                </p>
                            </v-card>
                        </v-col>
                        <v-col sm="12" md="4">
                            <v-card align="center">
                                <!-- <a href="http:\img/pr22_scrum.png" target="_blank"> -->
                                <img style="max-width:100%;" src="./img/pr22_scrum.png" />
                                <!-- </a> -->                            
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card class="eduRow">
                    <v-card class="rowTitle">
                        Overzicht fases
                    </v-card>
                    <v-row>
                        <v-col sm="12" md="12">
                            <v-card>
                                <img style="max-width:100%;" src="./img/fase15bord.png" />
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </div> 

            <div v-if="selTask && selTask.id =='pr22B'" class="px-6">
                
                <v-card class="eduRow">
                    <v-card class="rowTitle">
                        Fase 1
                    </v-card>
                    <v-row>
                        <v-col sm="12" md="8" >
                            <v-card>
                                <h3>De site</h3>
                                <p>
                                    Er moet een mooie aansprekende site komen met alle 750 Belgian beers.<br>
                                    Iedere gebruiker kan klikken op een Biertje uit de lijst, bij iedere klik gaat er een teller 
                                    omhoog. De drie meest geklikte (gelikete) biertjes komen prominent in beeld.<br>
                                    Het maakt niet uit hoe vaak een gebruiker klikt. 
                                </p>

                                <div v-if="selTask.deadline" class="white--text font-weight-bold my-3 px-2 text-center error">
                                    Deadline: {{selTask.deadline}}
                                </div>

                                <v-expansion-panels
                                    v-if="selTask.leerdoelen"
                                    v-model="panel"
                                    multiple
                                >
                                    <v-expansion-panel>
                                        <v-expansion-panel-header class="font-weight-bold primary lighten-4">Leerdoelen</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <div v-for="(cat, key) in selTask.leerdoelen"  :key="key" class="mt-2">
                                               <span class="font-weight-bold">{{key}}</span>
                                               <ul class="ul-min">
                                                <li v-for="(doel, index) in cat"  :key="index">{{doel}}</li>
                                               </ul>
                                            </div>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>

                            </v-card>
                        </v-col>
                        <v-col sm="12" md="4">
                            <v-card>
                                <img style="max-width:100%;" src="./img/pr22_biertjes.png" />
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card class="eduRow">
                    <v-card class="rowTitle">
                        <i>opdrachten fase 1</i>
                    </v-card>
                    <v-row>
                        <v-col sm="12" md="8" >
                            <v-card>
                                <h3>De tabel</h3>
                                <ol style="list-style-type: lower-alpha;">
                                    <li>
                                        Haal het MySQL script voor de 750 Belgische biersoorten <a href="https://std.stegion.nl/api_bier/bier.sql">hier</a> vandaan
                                    </li>
                                    <li>
                                        Open PHPMyAdmin op jouw laptop, selecteer een database en plak het SQL-script en voer het uit: de biertabel is gemaakt
                                    </li>
                                    <li>
                                        Voeg in PHPMyAdmin een veld toe: "likes" (gebruik meervoud, "like" is een gereserveerd woord voor MySQL)
                                    </li>
                                </ol>
                                <br>
                                <h3>PHP</h3>
                                <ol style="list-style-type: lower-alpha;" start="3">
                                    <li>
                                        Maak in eerste instantie met plain PHP (geen Laravel oid. gebruiken), een HTML site die alle biertjes op toont
                                    </li>
                                    <li>
                                        Open PHPMyAdmin op jouw laptop, selecteer een database en haal de biertabel binnen
                                    </li>
                                </ol>
                                <br>
                                <h3>HTML</h3>
                                <ol style="list-style-type: lower-alpha;" start="5">
                                    <li>
                                        Maak met HTML en CSS een een biertje-tabel aan
                                    </li>
                                    <li>
                                        Voeg een button (of een checkbox) toe waarmee een biertje kan liken
                                    </li>
                                    <li>
                                        Zorg dat de top 3 van meest ge-likte biertjes boven aan de pagina staan
                                    </li>
                                </ol>
                            </v-card>
                        </v-col>
                        <v-col sm="12" md="4">
                            <v-card>
                                <img style="max-width:80%;" src="./img/pr22_mysql.jpeg" />
                        
                                <img style="max-width:80%;" src="./img/pr22_php.png" />
                            </v-card>
                        </v-col>
                        
                    </v-row>
                </v-card>
            </div> 

            <div v-if="selTask && selTask.id =='pr22C'" class="px-6">
                <v-card class="eduRow">
                    <v-card class="rowTitle">
                        Fase 2
                    </v-card>
                    <v-row>
                        <v-col sm="12" md="8" >
                            <v-card>
                                Er moet nu ook bijgehouden worden dat niet gebruikers meerdere malen op een biertje kunnen klikken, 
                                dus per IP adres bijhouden of er al geklikt is. Want TBBCompany wil alleen unieke likes hebben.
                            
                                <div v-if="selTask.deadline" class="white--text font-weight-bold my-3 px-2 text-center error">
                                    Deadline: {{selTask.deadline}}
                                </div>

                                <v-expansion-panels
                                    class="mt-2"
                                    v-if="Object.keys(selTask.leerdoelen).length>0"
                                    v-model="panel"
                                    multiple
                                >
                                    <v-expansion-panel>
                                        <v-expansion-panel-header class="font-weight-bold primary lighten-4">Leerdoelen</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <div v-for="(cat, key) in selTask.leerdoelen"  :key="key" class="mt-2">
                                               <span class="font-weight-bold">{{key}}</span>
                                               <ul class="ul-min">
                                                <li v-for="(doel, index) in cat"  :key="index">{{doel}}</li>
                                               </ul>
                                            </div>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card class="eduRow">
                    <v-card class="rowTitle">
                        <i>opdrachten fase 2</i>
                    </v-card>
                    <v-row>
                        <v-col sm="12" md="12" >
                            <v-card>
                                <ol style="list-style-type: lower-alpha;" >
                                    <li>
                                        Verzin een list zodat je de opdracht van fase 2 voor elkaar krijgt ...
                                    </li>
                                </ol>
                            </v-card>
                        </v-col>
                        
                    </v-row>
                </v-card>
            </div> 

            <div v-if="selTask && selTask.id =='pr22D'" class="px-6">
                <v-card class="eduRow">
                    <v-card class="rowTitle">
                        Fase 3
                    </v-card>
                    <v-row>
                        <v-col sm="12" md="8" >
                            <v-card>
                                De TBBCompany wil nu ook dat gebruikers een profiel kunnen aanmaken en daarme zelf rating op een schaal 
                                van 1…5 sterren kunnen geven aan een biertje en ook een proefnotitie kunnen opschrijven.
                                
                                <div v-if="selTask.deadline" class="white--text font-weight-bold my-3 px-2 text-center error">
                                    Deadline: {{selTask.deadline}}
                                </div>

                                <v-expansion-panels
                                    class="mt-2"
                                    v-if="Object.keys(selTask.leerdoelen).length>0"
                                    v-model="panel"
                                    multiple
                                >
                                    <v-expansion-panel>
                                        <v-expansion-panel-header class="font-weight-bold primary lighten-4">Leerdoelen</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <div v-for="(cat, key) in selTask.leerdoelen"  :key="key" class="mt-2">
                                               <span class="font-weight-bold">{{key}}</span>
                                               <ul class="ul-min">
                                                <li v-for="(doel, index) in cat"  :key="index">{{doel}}</li>
                                               </ul>
                                            </div>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card class="eduRow">
                    <v-card class="rowTitle">
                        <i>opdrachten fase 2</i>
                    </v-card>
                    <v-row>
                        <v-col sm="12" md="8" >
                            <v-card>
                                <ol style="list-style-type: lower-alpha;" >
                                    <li>
                                        Verzin een list zodat je de opdracht van fase 3 voor elkaar krijgt ...
                                    </li>
                                </ol>
                            </v-card>
                        </v-col>
                        
                    </v-row>
                </v-card>
            </div> 

            <div v-if="selTask && selTask.id =='pr22E'" class="px-6">
                <v-card class="eduRow">
                    <v-card class="rowTitle">
                        Fase 4
                    </v-card>
                    <v-row>
                        <v-col sm="12" md="8" >
                            <v-card>
                                De TBBCompany wil nu ook een overzichtsrapport van hun populairste bieren.
                                <div v-if="selTask.deadline" class="white--text font-weight-bold my-3 px-2 text-center error">
                                    Deadline: {{selTask.deadline}}
                                </div>

                                <v-expansion-panels
                                    class="mt-2"
                                    v-if="Object.keys(selTask.leerdoelen).length>0"
                                    v-model="panel"
                                    multiple
                                >
                                    <v-expansion-panel>
                                        <v-expansion-panel-header class="font-weight-bold primary lighten-4">Leerdoelen</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <div v-for="(cat, key) in selTask.leerdoelen"  :key="key" class="mt-2">
                                               <span class="font-weight-bold">{{key}}</span>
                                               <ul class="ul-min">
                                                <li v-for="(doel, index) in cat"  :key="index">{{doel}}</li>
                                               </ul>
                                            </div>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card class="eduRow">
                    <v-card class="rowTitle">
                        <i>opdrachten fase 4</i>
                    </v-card>
                    <v-row>
                        <v-col sm="12" md="8" >
                            <v-card>
                                <ol style="list-style-type: lower-alpha;" >
                                    <li>
                                        Verzin een list zodat je de opdracht van fase 4 voor elkaar krijgt ...
                                    </li>
                                </ol>
                            </v-card>
                        </v-col>
                        
                    </v-row>
                </v-card>
            </div> 

            <div v-if="selTask && selTask.id =='pr22F'" class="px-6">
                <v-card class="eduRow">
                    <v-card class="rowTitle">
                        Fase 5
                    </v-card>
                    <v-row>
                        <v-col sm="12" md="8" >
                            <v-card>
                                De TBBCompany weet niet of ze nog langer met PHP willen ontwikkelen, ze willen graag ook een mobiele frontend op 
                                termijn. Daarom moet alle data naar de browser gestuurd worden in JSON formaat.
                                <div v-if="selTask.deadline"  class="white--text font-weight-bold my-3 px-2 error">
                                    <div>Deadline: </div>
                                    <div class="pl-2" v-html="selTask.deadline"></div>
                                  
                                </div>

                                <v-expansion-panels
                                    class="mt-2"
                                    v-if="Object.keys(selTask.leerdoelen).length>0"
                                    v-model="panel"
                                    multiple
                                >
                                    <v-expansion-panel>
                                        <v-expansion-panel-header class="font-weight-bold primary lighten-4">Leerdoelen</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <div v-for="(cat, key) in selTask.leerdoelen"  :key="key" class="mt-2">
                                               <span class="font-weight-bold">{{key}}</span>
                                               <ul class="ul-min">
                                                <li v-for="(doel, index) in cat" :key="index" v-html="doel"></li>
                                               </ul>
                                            </div>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>

                <v-card class="eduRow">
                    <v-card class="rowTitle">
                        <i>opdrachten fase 5</i>
                    </v-card>
                    <v-row>
                        <v-col sm="12" md="8" >
                            <v-card>
                                <ol style="list-style-type: lower-alpha;" >
                                    <li>
                                        Verzin een list zodat je de opdracht van fase 5 voor elkaar krijgt ...
                                    </li>
                                </ol>
                               
                            </v-card>
                        </v-col>
                        
                    </v-row>
                </v-card>
            </div> 

            <div v-if="selTask && selTask.id =='pr22G'" class="px-6">
                
                <v-card class="eduRow">
                    <v-card class="rowTitle">
                        Inleiding
                    </v-card>
                    <v-row>
                        <v-col sm="12" md="8" >
                            <v-card>
                                -
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>

                <!-- 
                <v-card class="eduRow">
                    <v-card class="rowTitle">
                        -
                    </v-card>
                    <v-row>
                        <v-col md="12" sm="12">
                            <v-card class="text-center">
                                -
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card> 
                -->
            </div> 
<!-- ---------- stop ---------------- project s2.2 ------------------------------------------------------------------------- -->
        </div>
    </div>
</template>

<script>
//import InlogDialog from "@/components/drawers_dialogs_bars/InlogDialog";


export default {
	//name: "eduBase"

    props: {
        selTaskProp: Object,
        selModuleProp: Object,
    },

	data: () => ({
        selTask: null,
        selModule:null,
        countClass:0,
        panel: [],
	}),

    watch: {
		//wanneer this.items veranderd van null naar gevuld, runt hij onderstaande
		selModuleProp: function (selModule) {//, oldItems
			if (selModule) {
                //console.log("newItems = ",newItem);
                this.selModule = selModule; 
                //console.log("this.selModule = ",this.selModule);
            }
        },
        selTaskProp: function (selTask) {//, oldItems
			if (selTask) {
                //console.log("newItems = ",newItem);
                this.selTask = selTask;
                //console.log("this.selTask = ",this.selTask);
            }
        },
        
    },

    mounted() {
        this.selModule = this.selModuleProp;
        this.selTask   = this.selTaskProp;
        // console.log(" mounted this.selModule = ",this.selModule);
        // console.log(" mounted this.selTask = ",this.selTask);
        //let elms = this.$refs["div"];
        //let elms = this.$el.querySelector('.rowTitle')
        //console.log("elms rowTitle =", this.$el)
    },

    methods: {
        toggleExpansion : function() {
           console.log("clicked this=", this.$refs)
        },

        nextPage: function(right) {
            const index = this.selModule.tasks.findIndex(object => {return object.id === this.selTask.id;});
            //console.log("index = ", index);
            if(right) {
                //var index = this.selModule.tasks.map(function(e) { return e.id; }).indexOf(this.selTask.id); 
                if(index+1 <= this.selModule.tasks.length) { //controle eigenlijk overbodig omdat button dan disabled is
                    this.selTask = this.selModule.tasks[index+1]
                }                
            } else {
                if(index-1 >= 0) { //controle eigenlijk overbodig omdat button dan disabled is
                    this.selTask = this.selModule.tasks[index-1]
                }   
            }
        },
    }

};
</script>

<style> 
    @import './edu.css';
    h1, h2, h3, h4 { /* moet specifieker als hij in edu.css staat, daarom hier */
        margin: 0 0 0.3em 0;
    }

    .ul-min {
        margin:0;
        margin-bottom:5px;
    }
</style>
